<sm-card class="project-card"
         (click)="projectClicked()"
         [isExample]="!['All Experiments'].includes(project.name) && !isRootProject && (!project.company || ! project.company.id)"
         [isFolder]="true"
         [oneTabMode]="!(project?.sub_projects?.length>0)"
         [subFolderTitle]="project?.sub_projects?.length + ' sub projects'"
>
  <div secondTab class="sub-projects-list">
    <cdk-virtual-scroll-viewport [itemSize]="30" [minBufferPx]="440" [maxBufferPx]="660" class="h-100">
      <a
        *cdkVirtualFor="let subProject of project?.sub_projects; trackBy: trackById"
        class="d-flex align-items-center"
        smClickStopPropagation
        (click)="subProjectClicked(subProject.id)"
        [smTooltip]="subProject?.name"
        [matTooltipShowDelay]="300"
        [innerHTML]="subProject?.name| breadcrumbsEllipsis | purify "
      ></a>
    </cdk-virtual-scroll-viewport>
  </div>
  <div header-content class="d-flex justify-content-between align-items-center card-name">
    <sm-inline-edit #projectName
                    class="title edit-name dark"
                    [originalText]="project.basename"
                    [editable]="true"
                    [minLength]="2"
                    [required]="true"
                    pattern="^[^\/]{2,}$"
                    [forbiddenString]="projectsNames"
                    [inlineDisabled]="true"
                    (textChanged)="prepareProjectNameForChange($event)"
    >
      <span class="project-name"
            [smTooltip]="project.name"
            smShowTooltipIfEllipsis
            [showAlwaysTooltip] = "(project.name?.includes('/'))"
      >{{project.basename}}</span>
    </sm-inline-edit>
    <div *ngIf="project.system_tags?.includes('default')" class="justify-content-end project-tag">Sample</div>
    <sm-project-card-menu-extended
      class="menu-wrapper"
      *ngIf="!hideMenu"
      (deleteProjectClicked)="deleteProjectClicked.emit($event)"
      (moveToClicked)="moveToClicked.emit($event)"
      (newProjectClicked)="newProjectClicked.emit($event)"
      (projectNameInlineActivated)="projectName.inlineActivated()"
      [project]="project"
    >
    </sm-project-card-menu-extended>
  </div>
  <div class="d-flex justify-content-around w-100">
    <ng-template></ng-template>
    <sm-circle-counter
      [counter]="project.id === '*' ? '∞' : project.stats?.active?.total_tasks ?? '0'"
      label="TOTAL"
      [type]="project.stats?.active?.total_tasks===0 ? circleTypeEnum.empty : circleTypeEnum.pending"></sm-circle-counter>
    <sm-circle-counter
      [counter]="project.id === '*' ? '∞' : project.stats?.active?.status_count?.in_progress ?? '0'"
      label="RUNNING"
      [type]="project.stats?.active?.status_count?.in_progress ===0 ? circleTypeEnum.empty : circleTypeEnum.running"></sm-circle-counter>
    <sm-circle-counter [counter]="project.id === '*' ? '∞' : project.stats?.active?.completed_tasks_24h ?? '0'"
                       label="COMPLETED"
                       [type]="project.stats?.active?.completed_tasks_24h ===0 ? circleTypeEnum.empty : circleTypeEnum.completed"
                       underLabel="(24 hrs)"></sm-circle-counter>
  </div>
  <div class="d-flex-center" footer>
    <div class="tag"><i *ngIf="hidden" class="al-icon al-ico-ghost sm-md"></i></div>
    <div class="footer-title" *ngIf="project?.stats">
      COMPUTE TIME: {{computeTime}}
    </div>
    <div class="tag"></div>
  </div>
</sm-card>
