<sm-dialog-template *ngIf="data; else content" iconClass="al-icon al-ico-pipelines" header="CREATE NEW PIPELINE">
<ng-container *ngTemplateOutlet="content"></ng-container>
</sm-dialog-template>




<ng-template #content>
  <div class="content">
    <div class="code">
      <div class="d-flex justify-content-between">
        <div>
          Run this code for a quick example (Requires ClearML 1.3 or above)<br>
          For more details see the <a [class.dark]="createButton" href="https://clear.ml/docs/latest/docs/pipelines/"
                                      target="_blank">documentation</a>
        </div>
      </div>
      <sm-code-editor *ngIf="initPipelineCode" [code]="initPipelineCode" [readonly]="true" [showCopyButton]="true"></sm-code-editor>
    </div>
    <div class="diagram">
      <i class="i-pipeline-empty-state"></i>
    </div>
  </div>
</ng-template>